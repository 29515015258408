
import React, { Component, Fragment } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import axios from '../axios.js';
import { Link } from 'react-router-dom';
//import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
var FontAwesome = require('react-fontawesome')
var style = {
  container: {
    display: 'flex',
    flexWrap:'wrap',
    justifyContent: 'space-around',
    flexDirection: 'row',
    backgroundSize: 'cover',
    height: '100vh',
    overflow: 'auto',
    backgroundImage: 'url(' + require('../bg.jpg') + ')'
  },
  login: {
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    width: '25rem',
    height: '40rem',
    alignSelf: 'center'
  },
  logo: {
    alignSelf: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '48px',
    color: '#74b2de',
  },
}
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidePassword: true,
      Username: '',
      Password: '',
      error_Credentials: false,
      error_message: '',
      signin: false,
      register: false,
      token: '',
    };
    this.login = this.login.bind(this);
  }
  componentDidMount() {
 
  }

  login(event) {
    event.preventDefault();
    axios.post('/rest-auth/login/', {
      username: this.state.Username,
      password: this.state.Password,
    })
      .then(async response => {
        let result = JSON.parse(response.request.response);
        axios.defaults.headers.common.Authorization =
          'Token ' + result.key;
        localStorage.setItem('token', result.key);
        this.setState({ signin: true, token: result.key });
        this.props.history.replace("/Cockpit");
      })
      .catch(
        function (error) {
          console.log(error);
          //if credentials error
          if (error.response) {
            if (
              JSON.parse(error.response.request.response).hasOwnProperty(
                'non_field_errors',
              )
            ) {
              JSON.parse(
                error.response.request.response,
              ).non_field_errors.forEach(el => {
                if (el.includes('Unable to log in with provided credentials.')) {
                  this.setState(
                    {
                      error_Credentials: true,
                      error_message: 'Wrong Username or Password',
                    }
                  );
                }
              });
            }
          } else {
            //if request faild
            this.setState({
              error_Credentials: true,
              error_message: 'Network error.',
            });
          }

        }.bind(this)
      );
  }
  render() {
    return (
      <Fragment>
            <Fragment>
              <div style={style.container}>
                <div className="mr-md-5" style={{ display: 'flex' }}>
                  <Card style={style.login} className="shadow rounded">
                    <img
                      alt=""
                      src={require('../logo.png')}
                      width="240"
                      height="79"
                      style={{ alignSelf: 'center', marginTop: '5rem' }}
                      data-test="Logo"
                    />
                    <Card.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', marginTop: '5rem', flexDirection: 'row' }}>
                      <Form className="col-10">
                        <Form.Group controlId="formBasicEmail" className="row ">
                          <Form.Control type="text" placeholder="Enter Username" data-test="Username" onChange={e => this.setState({ Username: e.target.value })}
                            className={this.state.error_Credentials === true ? "is-invalid" : null} />

                        </Form.Group>

                        <Form.Group controlId="formBasicPassword" className="row" style={{ justifyContent: 'flex-end' }}>
                          <Form.Control type={this.state.hidePassword === true ? "password" : "text"}  className={this.state.error_Credentials === true ? "is-invalid" : null}
                            placeholder="Password" onChange={e => this.setState({ Password: e.target.value })} data-test="Password"/>
                           <FontAwesome data-test="show_password" className="" name="eye-slash" style={{ position: 'absolute', alignSelf: 'center' }} onClick={() => this.setState({ hidePassword: !this.state.hidePassword })}/>
                          {/* <VisibilityOffIcon className="" style={{ position: 'absolute', alignSelf: 'center' }} onClick={() => this.setState({ hidePassword: !this.state.hidePassword })} /> */}
                        </Form.Group>
                        <p
                        data-test="Error"
                          style={{ color: 'red', textAlign: 'center' }}
                          hidden={this.state.error_Credentials === true ? false : true}>
                          {this.state.error_message}
                        </p>
                        <Button disabled={this.state.Username !== '' && this.state.Password !== '' ? false : true} data-test="Button"
                          onClick={(event) => this.login(event)} variant="primary" type="submit" style={{ backgroundColor: '#161525', float: 'right' }}>
                          Login</Button>
                        <Link  data-test="signup"  to="/Register" onClick={() => this.setState({ register: true })}>or SignUp??</Link>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>
                <h3 data-test="healthy living" style={style.logo}>healthy living</h3>
              </div>

            </Fragment>

      </Fragment>

    );
  }
}
export default Login;


