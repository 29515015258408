
import React, { Component, Fragment } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from '../axios.js';
import { truncate } from 'lodash';
//import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
var FontAwesome = require('react-fontawesome')
var style = {
  container: {
    display: 'flex',
    flexWrap:'wrap',
    justifyContent: 'space-around',
    flexDirection: 'row',
    backgroundSize: 'cover',
    height: '100vh',
    overflow: 'auto',
    backgroundImage: 'url(' + require('../bg.jpg') + ')'
  },
  login: {
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    width: '25rem',
    height: '40rem',
    alignSelf: 'center'
  },
  logo: {
    alignSelf: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '48px',
    color: '#74b2de',
  },
}
export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidePassword: true,
      hideConfirm: true,
      Username: '',
      Password: '',
      terms: false,
      ConfirmPass: '',
      Email: '',
      signin: false,
      error_Credentials: false,
      error_message: '',
    }
    this.SignUp = this.SignUp.bind(this);
    this.confirm = this.confirm.bind(this);
  }
  SignUp(event) {
    event.preventDefault();
    //this.setState({ signin: true });
    axios.post('/users/', {
      username: this.state.Username,
      password: this.state.Password,
      email: this.state.Email,
    })
      .then(async response => {
        JSON.parse(response.request.response);
        this.setState({ signin: true });
        this.props.history.replace("/Login");
      })
      .catch(
        function (error) {
          console.log(error);
          if (
            JSON.parse(error.response.request.response).hasOwnProperty(
              'non_field_errors',
            )
          ) {
            JSON.parse(
              error.response.request.response,
            ).non_field_errors.forEach(el => {
              if (el.includes('The fields email must make a unique set.')) {
                this.setState(
                  {
                    error_Credentials: true,
                    error_message: 'there exist an account with this email.',
                  }
                );
              }
            });
          }
          if (
            JSON.parse(error.response.request.response).hasOwnProperty(
              'username',
            )
          ) {
            JSON.parse(
              error.response.request.response,
            ).username.forEach(el => {
              if (el.includes('A user with that username already exists.')) {
                this.setState(
                  {
                    error_Credentials: true,
                    error_message: 'A user with that username already exists.',
                  }
                );
              }
              if (el.includes('Enter a valid username. This value may contain only letters, numbers, and @/./+/-/_ characters.')) {
                this.setState(
                  {
                    error_Credentials: true,
                    error_message: 'Enter a valid username.',
                  }
                );
              }
            });
          } if (
            JSON.parse(error.response.request.response).hasOwnProperty(
              'email')
          ) {
            JSON.parse(
              error.response.request.response,
            ).email.forEach(el => {
              if (el.includes('Enter a valid email address.')) {
                this.setState(
                  {
                    error_Credentials: true,
                    error_message: 'Enter a valid email address.',
                  }
                );
              }
            });
          }
        }.bind(this)
      );
  }
  confirm(e) {
    if (e.target.value === this.state.Password) {
      this.setState({ ConfirmPass: e.target.value, error_Credentials: false, error_message: "" })
    }
    else if (e.target.value === '') {
      this.setState({ ConfirmPass: '', error_Credentials: false, error_message: "" })
    }
    else {
      this.setState({ ConfirmPass: '', error_Credentials: true, error_message: "password doesn't match" })
    }
  }
  render() {
    return (
      <Fragment>
          <Fragment>
            <div style={style.container}>
              <div className="mr-md-5" style={{ display: 'flex' }}>
                <Card style={style.login} className="shadow rounded">
                <img
                data-test="Logo"
                  alt=""
                  src={require('../logo.png')}
                  width="240"
                  height="79"
                  style={{alignSelf: 'center',marginTop: '5rem'}}
                /> 
                  <Card.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Form className="col-10">
                      <Form.Group controlId="formBasicEmail" className="row ">
                        <Form.Control type="text" data-test="Username" className={this.state.error_Credentials === true ? "is-invalid" : null}
                          placeholder="Enter Username" onChange={e => this.setState({ Username: e.target.value })} />

                      </Form.Group>
                      <Form.Group controlId="formBasicEmail" className="row ">
                        <Form.Control type="email" data-test="Email" className={this.state.error_Credentials === true ? "is-invalid" : null}
                          placeholder="Enter email" onChange={e => this.setState({ Email: e.target.value })} />

                      </Form.Group>

                      <Form.Group controlId="formBasicPassword" className="row " style={{ justifyContent: 'flex-end' }}>
                        <Form.Control type={this.state.hidePassword === true ? "password" : "text"} className={this.state.error_Credentials === true ? "is-invalid" : null}
                          placeholder="Password" data-test="Password" onChange={e => this.setState({ Password: e.target.value })} />
                        <FontAwesome data-test="show_password" className="" name="eye-slash" style={{ position: 'absolute', alignSelf: 'center' }} onClick={() => this.setState({ hidePassword: !this.state.hidePassword })}/>
                        {/* <VisibilityOffIcon className="" style={{ position: 'absolute', alignSelf: 'center' }} onClick={() => this.setState({ hidePassword: !this.state.hidePassword })} /> */}
                      </Form.Group>
                      <Form.Group className="row " style={{ justifyContent: 'flex-end' }}>
                        <Form.Control type={this.state.hideConfirm === true ? "password" : "text"} className={this.state.error_Credentials === true ? "is-invalid" : null}
                          placeholder="Confirm Password" data-test="Confirm Password" onChange={e => this.confirm(e)} />
                             <FontAwesome className="" name="eye-slash" style={{ position: 'absolute', alignSelf: 'center' }} onClick={() => this.setState({ hideConfirm: !this.state.hideConfirm })}/>
                        {/* <VisibilityOffIcon className="" style={{ position: 'absolute', alignSelf: 'center' }} onClick={() => this.setState({ hideConfirm: !this.state.hideConfirm })} /> */}
                      </Form.Group>
                      <Form.Group controlId="formcheck" >
                        <Form.Check type="checkbox" data-test="terms" label={<p>I agree on <a href="http://www.waeev.com">terms and conditions</a></p>}  onChange={e => this.setState({ terms: !this.state.terms })} />

                      </Form.Group>
                      <p
                        style={{ color: 'red', textAlign: 'center' }}
                        hidden={this.state.error_Credentials === true ? false : true}>
                        {this.state.error_message}
                      </p>
                      <Button data-test="Button" onClick={(e) => this.SignUp(e)} variant="primary" type="submit" style={{ backgroundColor: '#161525', float: 'right' }}
                        disabled={this.state.Username !== '' && this.state.Password !== '' && this.state.Email !== '' && this.state.ConfirmPass !== '' && this.state.terms === true ? false : true}>
                        Register</Button>
                      <Link data-test="signin" to="/Login" onClick={() => this.setState({ signin: true })}>or SignIn?</Link>
                    </Form>
                  </Card.Body>
                </Card>
              </div>
              <h3 style={style.logo}>healthy living</h3>
              </div>
                    </Fragment>
            </Fragment>



        );
    }
}

