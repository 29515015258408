import React, { Fragment, Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Readings from './pages/Readings.js';
import Profile from './pages/Profile.js';
import About from './pages/About.js';
import Registration from './pages/Registration.js';
import Login from './pages/Login.js';
import Cockpit from './pages/Cockpit.js';
import Charts from './pages/Charts.js';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logged: false,
      path: '',
    }
  }
  componentDidMount() {
    let data = localStorage.getItem('token');
    if (data) {
      this.setState({ logged: true,path: '/Cockpit' })
    }else{
      this.setState({ path: '/Login' })
    }
  }
  render() {
    return (
      <Fragment>

          <BrowserRouter>
            <Switch>
              <Route exact path="/">
                <Redirect to={this.state.path} />
              </Route>
              <Route path="/Login" component={ Login } />
              <Route path="/Register" component={ Registration } />
              <Route path="/Readings" component={Readings} />
              <Route path="/Cockpit" component={Cockpit} />
              <Route path="/Charts" component={ Charts } />
              <Route path="/About" component={About} />
              <Route path="/Profile" component={ Profile } />
            </Switch>
          </BrowserRouter>
  
      </Fragment>

    );
  }

}

export default App;
