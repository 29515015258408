/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import '../App.css';
import axios from '../axios.js';
export default class NavB extends Component {

  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
    }
  }
  log_out() {
    localStorage.setItem('token', '');
    delete axios.defaults.headers.common["Authorization"];
  }
  render() {


    return (

      <Navbar style={{ backgroundColor: "#FFFFFF" }} expand="lg" onToggle={() => this.setState({ toggle: !this.state.toggle })} className="shadow rounded">
        {/* <Navbar.Brand href="#"> */}
        <NavLink to="/Cockpit" > 
          <img
            src={require('../logo.png')}
            width="150"
            height="50"
            className="d-inline-block align-top"
          />
          </NavLink>
        {/* </Navbar.Brand> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {this.state.toggle === true ?
          <Navbar.Collapse id="basic-navbar-nav" style={{ background: 'ghostwhite', flexDirection: 'column', display: 'flex', alignItems: 'center', width: '10px' }}>
            <Nav className="mr-auto"  >
            </Nav>
            <NavLink to="/Profile">Profile</NavLink>
            {/* <NavLink to="/Readings">Readings</NavLink> */}
            <NavLink to="/Cockpit">Cockpit </NavLink>
            <Nav.Link href="http://www.waeev.com" style={{padding:'0px' }}>About</Nav.Link>
            <NavLink to="/Login" onClick={() => this.log_out()} >Logout </NavLink>
          </Navbar.Collapse>
          :
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto"  >
            </Nav>
            <NavLink activeClassName="active" to="/Profile" style={{ marginRight: '10px' }}>Profile </NavLink>
            {/* <NavLink to="/Readings" style={{ marginRight: '10px' }}>Readings </NavLink> */}
            <NavLink to="/Cockpit" style={{ marginRight: '10px' }}>Cockpit </NavLink>
            <Nav.Link href="http://www.waeev.com" style={{ marginRight: '10px',padding:'0px' }}>About</Nav.Link>
            <NavLink data-test="logout" to="/Login" style={{ marginRight: '10px' }} onClick={() => this.log_out()} >Logout </NavLink>
          </Navbar.Collapse>
        }

      </Navbar>


    );
  }
}

