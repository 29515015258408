import React, { Fragment, Component } from 'react';
import NavBar from '../component/NavBar.js';
import axios from '../axios.js';
import { Button } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import '../App.css';
var FontAwesome = require('react-fontawesome')
export default class Cockpit extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            DeviceList: [],
            deviceIDs: [],
            online: false,
            loading: false,
            series: [(250 * 100 / 500)],
            options: {
                chart: {
                    height: 350,
                    type: 'radialBar',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 225,
                        hollow: {
                            margin: 0,
                            size: '70%',
                            background: '#fff',
                            image: undefined,
                            imageOffsetX: 0,
                            imageOffsetY: 0,
                            position: 'front',
                            dropShadow: {
                                enabled: true,
                                top: 3,
                                left: 0,
                                blur: 4,
                                opacity: 0.24
                            }
                        },
                        track: {
                            background: '#fff',
                            strokeWidth: '67%',
                            margin: 0, // margin is in pixels
                            dropShadow: {
                                enabled: true,
                                top: -3,
                                left: 0,
                                blur: 4,
                                opacity: 0.35
                            }
                        },

                        dataLabels: {
                            show: true,
                            name: {
                                offsetY: -5,
                                show: true,
                                color: '#888',
                                fontSize: '17px'
                            },
                            value: {
                                formatter: function (val) {
                                    return parseInt(val);
                                },
                                offsetY: 5,
                                color: '#111',
                                fontSize: '20px',
                                show: true,
                            }
                        }
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        shadeIntensity: 0.5,
                        gradientToColors: ['#ABE5A1'],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100]
                    }
                },
                stroke: {
                    lineCap: 'round'
                },
                labels: ['WQI'],
            },
        }
    };
    get_deviceID() {
        //set DeviceList
        axios.get('/rest-auth/user/')
            .then(async response => {
                let result = JSON.parse(response.request.response);
                console.log(result);
                this.setState({ DeviceList: result.devices });
                var openWS = setInterval(() => {
                    console.log("Waiting web socket is opening");///////////////////////////
                    if (this.socket.readyState === 1) {
                        console.log("socket readystate setinterval");///////////////////////////
                        clearInterval(openWS);
                        result.devices.map(el => {
                            console.log("result.devices.map");
                            this.socket.send(
                                JSON.stringify({ publish: { device: el.pk, status: 'msg' } }),
                            );
                            console.log("after socket send");
                            el.statusInterval = setInterval(() => {
                                console.log("statusInterval");
                                this.socket.send(
                                    JSON.stringify({ publish: { device: el.pk, status: 'msg' } }),
                                );
                            }, 60000);
                            this.setState({ deviceIDs: [...this.state.deviceIDs, el.pk] }, () => {
                                if (this._isMounted === true) {
                                    console.log("get_deviceID _isMounted true");
                                    this.setState({ loading: true });
                                    this.socket.send(
                                        JSON.stringify({ subscribe: this.state.deviceIDs }),
                                    );
                                }
                            })

                        })
                    }
                }, 500)

            })
            .catch(
                function (error) {
                    if (
                        JSON.parse(error.response.request.response).hasOwnProperty(
                            'detail',
                        )
                    ) {
                        if (JSON.parse(
                            error.response.request.response,
                        ).detail.includes('Invalid token.')) {
                            this.props.history.replace('/Login')
                        }
                    }
                }.bind(this)
            );
    }
    componentDidMount() {
        this._isMounted = true;
        let data = localStorage.getItem('token');
        if (data) {
            axios.defaults.headers.common.Authorization = 'Token ' + data;
            this.get_deviceID();
        } else {
            this.props.history.replace("/Login");
        }

        //open websocket to get readings
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            // dev code
            this.socket = new WebSocket(
                'ws://dev-diylife.co:8100/ws/devices/?token=' + data,
            );
        } else {
            // production code
            this.socket = new WebSocket(
                (window.location.protocol==='http'? 'ws://':'wss://')+window.location.host+'/ws/devices/?token=' + data,
            );
        }
        let onDevice = [];
        //get readings
        this.socket.onmessage = function (event) {
            console.log("socket onmessage");

            this.setState({ loading: false });
            var response = JSON.parse(event.data);
            console.log(response);
            //check for same device id in our list to append its readings
            if (response.hasOwnProperty('reading')) {
                console.log("hasOwnProperty reading");
                if (this.state.DeviceList.find(el => el.pk === response.device)) {
                    this.state.DeviceList.find(el => el.pk === response.device).reading = response.reading;
                }

            }
            let temp = this.state.DeviceList;
            if (response.hasOwnProperty('status')) {

                console.log("hasOwnProperty status");
                if (temp.find(el => el.pk === response.device)) {
                    let device = temp.find(el => el.pk === response.device)
                    device.status = response.status;
                    if(device.onlineTimeout !== undefined){
                        clearTimeout(device.onlineTimeout);
                    }
                     device.online = true;
                     clearInterval(device.statusInterval);
                     device.statusInterval = setInterval(() => {
                        console.log(device.pk);
                        this.socket.send(
                            JSON.stringify({ publish: { device: device.pk, status: 'msg' } }),
                        );
                    }, 60000 + ((Math.random()-1 )*20000));
                    device.onlineTimeout=  setTimeout(() => {
                        device.online = false
                        this.setState({DeviceList: temp});
                        },70000);
                }
                this.setState({DeviceList: temp});
            }
        }.bind(this);
        this.socket.onerror = function (error) {
            console.log('error', error.message);
        };
        this.socket.onclose = function (event) { };


    }
    componentWillUnmount() {
        this.socket.close();
        this._isMounted = false;
        clearInterval(this.statusInterval);
    }
    render() {
        let device = [];
        let color = '';
        if (this.state.DeviceList.length !== 0) {
            this.state.DeviceList.map(el => {
                let icons = [];
                for (let x in el.reading) {
                    // eslint-disable-next-line default-case
                    switch (x) {
                        case 'B_T':
                            if (el.reading.B_T >= 0 && el.reading.B_T < 26)
                                color = '#00C2FF'
                            else if (el.reading.B_T >= 26 && el.reading.B_T < 30)
                                color = '#FFD600'
                            else
                                color = 'red'
                            icons.push(<div key={el.reading[x] + 1} id="circle" style={{ backgroundColor: color, marginRight: '2px' }}><FontAwesome className="" name="thermometer-half" /></div>)
                            break;
                        case 'B_H':
                            if ((el.reading.B_H >= 0 && el.reading.B_H < 30) || (el.reading.B_H >= 70 && el.reading.B_H < 100))
                                color = 'red'
                            else if ((el.reading.B_H >= 30 && el.reading.B_H < 40) || (el.reading.B_H >= 60 && el.reading.B_H < 70))
                                color = '#FFD600'
                            else
                                color = '#00C2FF'
                            icons.push(<div key={el.reading[x] + 2} id="circle" style={{ backgroundColor: color, marginRight: '2px' }}> <FontAwesome className="" name="tint" /></div>)
                            break;
                        case 'c_i':
                            if (el.reading.c_i >= 0 && el.reading.c_i < 150)
                                color = '#00C2FF'
                            else if (el.reading.c_i >= 150 && el.reading.c_i < 251)
                                color = '#FFD600'
                            else
                                color = 'red'
                            icons.push(<div key={el.reading[x] + 3} id="circle" style={{ backgroundColor: color, marginRight: '2px' }}><FontAwesome className="" name="leaf" /></div>)
                            break;
                        case 'c_c':
                            if (el.reading.c_c >= 400 && el.reading.c_c < 2500)
                                color = '#00C2FF'
                            else if (el.reading.c_c >= 2500 && el.reading.c_c < 4000)
                                color = '#FFD600'
                            else
                                color = 'red'
                            icons.push(<div key={el.reading[x] + 4} id="circle" style={{ backgroundColor: color, marginRight: '2px' }}> <FontAwesome className="" name="cloud" /></div>)
                            break;
                        case 'c_v':
                            if (el.reading.c_v >= 0 && el.reading.c_v < 300)
                                color = '#00C2FF'
                            else if (el.reading.c_v >= 300 && el.reading.c_v < 600)
                                color = '#FFD600'
                            else
                                color = 'red'
                            icons.push(<div key={el.reading[x] + 5} id="circle" style={{ backgroundColor: color, marginRight: '2px' }}> <FontAwesome className="" name="flask" /></div>)
                            break;
                        default:
                            color = 'white'
                    }
                }
                device.push(<Button data-test="Button" key={el.pk} onClick={() => { console.log(el.device_type); this.props.history.push("/Readings", { pk: el.pk, label: el.label, control: el.is_controllable, type: el.device_type, mac: el.mac_address, fv: el.firmware_version }); }}
                    className="my-3 mx-2 shadow rounded" id="button" style={{ border: 'none' }}>
                    {el.online === true ? <span id="online" data-test="online">●</span> : <span id="offline" data-test="online">●</span>}{el.label}{el.pk}
                    {this.state.loading === true ? <p>loading...</p> : <div style={{ height: '137px' }}><Chart data-test="chart" options={this.state.options}
                        series={el.reading !== undefined && el.reading.hasOwnProperty('c_w') ? [(el.reading.c_w)] : [0]} type="radialBar" height={137} /></div>}
                    <div data-test="icon" className="row" style={{ color: 'white', justifyContent: 'center', height: '25px' }}>
                        {icons}
                    </div>
                </Button>)
            })
        }
        return (
            <div style={{ overflowX: 'hidden', height: '100vh' }}>
                <Fragment>
                    <NavBar />
                    {device}
                </Fragment>
            </div >


        );
    }

}

