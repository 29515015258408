const axios = require('axios');
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  axios.defaults.baseURL = 'http://dev-diylife.co:8100/rest-api/';
} else {
  // production code
  axios.defaults.baseURL = '/rest-api/';
}
export default axios;
