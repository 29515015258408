/* eslint-disable no-fallthrough */

import React, { Component, Fragment } from 'react';
import NavBar from '../component/NavBar.js';
import { Button, Card, Modal } from 'react-bootstrap';
import axios from '../axios.js';
import '../App.css';
var FontAwesome = require('react-fontawesome')
export default class Readings extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      DeviceList: [],
      deviceDetails: false,
      active: [],
      mqtt: '',
      loading: false,
      lastReading: null,
      now: new Date(),
      deviceID: this.props.location.state !== undefined ? this.props.location.state.pk : null,
      Sensorname: '',
      control: this.props.location.state !== undefined ? this.props.location.state.control : null,
      readings: {},
      fan: '',
    };
    this.ControlFan = this.ControlFan.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    let data = localStorage.getItem('token');
    if (data) {
      axios.defaults.headers.common.Authorization =
        'Token ' + data;
      if (this.props.location.state === undefined) {
        this.props.history.replace('/Cockpit');
      } else {
        this.GetReadings();
      }

    } else {
      this.props.history.replace("/Login");
    }
    //open websocket to get readings
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // dev code
      this.socket = new WebSocket(
        'ws://dev-diylife.co:8100/ws/devices/?token=' + data,
      );
    } else {
      // production code
      this.socket = new WebSocket(
        (window.location.protocol ==='http'? 'ws://':'wss://')+window.location.host+'/ws/devices/?token=' + data,
      );
    }
    this.socket.onopen = () => {
      this.socket.send(
        JSON.stringify({ subscribe: [this.props.location.state.pk] }),
      );
      this.socket.send(
        JSON.stringify({ publish: { device: this.props.location.state.pk, status: 'msg' } }),
      );
    };
    //get readings
    this.socket.onmessage = function (event) {
      var response = JSON.parse(event.data);
      if (response.hasOwnProperty('reading')) {
        this.setState({ readings: response.reading, loading: false }, () => { clearInterval(this.lastRead); this.getLastReading() })
      }
      if (response.hasOwnProperty('fan')) {
        this.setState({ fan: response.fan })
      }
      if (response.hasOwnProperty('status')) {
        this.setState({ mqtt: 'connected' })
      }
    }.bind(this);
    this.socket.onerror = function (error) {
      console.log('error', error.message);
    };
    this.socket.onclose = function (event) { };
  }
  GetReadings() {
    this.setState({
      loading: true,
      active: [],
    })
    //subscribe to id selected
    if (this._isMounted === true) {
      this.getLastReading();

    }
  }
  getLastReading() {
    axios.get('/devices/' + this.state.deviceID + '/readings/?reading=temperature&interval=last')
      .then(async response => {
        let result = JSON.parse(response.request.response);
        if(result.length === 0){
          this.setState({lastReading: '+1 hour ago'})
        }else{
          this.setState({ now: new Date() }, () => {
            let d = new Date(this.state.now - new Date(result[0]))
            if (d.getUTCMinutes() === 0) {
              this.setState({ lastReading: d.getUTCSeconds() + ' sec ago' });
            } else {
              this.setState({ lastReading: d.getUTCMinutes() + ' min ago' });
            }
          })
          this.lastRead = setInterval(() => {
            this.setState({ now: new Date() }, () => {
              let d = new Date(this.state.now - new Date(result[0]))
              if (d.getUTCMinutes() === 0) {
                this.setState({ lastReading: d.getUTCSeconds() + ' sec ago' });
              } else {
                this.setState({ lastReading: d.getUTCMinutes() + ' min ago' });
              }
            })
          }, 5000);
        }

      })
      .catch(
        function (error) {
          console.log(error);
        }
      );
  }

  ControlFan() {
    this.socket.send(
      JSON.stringify({ publish: { device: this.state.deviceID, fan: this.state.fan } }),
    );

  }
  componentWillUnmount() {
    this._isMounted = false;
    if (this._isMounted === false) {
      this.socket.close();
      clearInterval(this.lastRead);
    }
  }
  render() {
    let items = [];
    let name = '';
    let icon = '';
    let unit = '';
    if (this.state.DeviceList.length === 0) {
      items.push(<option key={'1'} disabled>select a device</option>)
    } else {
      this.state.DeviceList.map((el) => {
        items.push(<option key={el.pk} value={el.pk}>{el.mac_address}</option>)
      })
    }
    let button = [];
    let i = 0;
    let color = '';
    if (this.state.readings === {}) {
      button.push(<div key={'1'} style={{ textAlign: 'center' }}><h4>No readings</h4><hr /></div>)
    } else {
      for (let x in this.state.readings) {
        // eslint-disable-next-line default-case
        switch (x) {
          case 'B_T':
            name = 'Temperature';
            unit = '\xB0C';
            if (this.state.readings.B_T >= 0 && this.state.readings.B_T < 26)
              color = '#00C2FF'
            else if (this.state.readings.B_T >= 26 && this.state.readings.B_T < 30)
              color = '#FFD600'
            else
              color = 'red'
            icon = <div id="circle" style={{ backgroundColor: color }}><FontAwesome className="" name="thermometer-half" /></div>;
            break;
          case 'B_P':
            name = 'Pressure';
            unit = 'hPa';
            color = '#00C2FF';
            icon = <div id="circle" style={{ backgroundColor: color }}> <FontAwesome className="" name="tachometer" /></div>;
            break;

          case 'L':
            name = 'LightLevel';
            unit = 'Lx';
            color = '#00C2FF';
            icon = <div id="circle" style={{ backgroundColor: color }}> <FontAwesome className="" name="lightbulb-o" /></div>;
            break;
          case 'S':
            name = 'Sound';
            unit = '';
            color = '#00C2FF';
            icon = <div id="circle" style={{ backgroundColor: color }}> <FontAwesome className="" name="volume-down" /></div>;
            break;
          case 'M':
            name = 'Gas';
            unit = 'ppm';
            color = '#00C2FF';
            icon = <div id="circle" style={{ backgroundColor: color }}> <FontAwesome className="" name="skyatlas" /></div>;
            break;
          case 'P':
            name = 'Presence';
            unit = '';
            color = '#00C2FF';
            icon = <div id="circle" style={{ backgroundColor: color }}> <FontAwesome className="" name="child" /></div>;
            break;
          case 'A':
            name = 'Acceleration';
            // unit = <p>m/s<sup>2</sup></p>;
            unit = 'm/s^2'
            color = '#00C2FF';
            icon = <div id="circle" style={{ backgroundColor: color }}> <FontAwesome className="" name="bolt" /></div>;
            break;
          case 'PM1':
            name = 'PM1';
            unit = 'ug/m3';
            color = '#00C2FF';
            icon = <div id="circle" style={{ backgroundColor: color }}> <FontAwesome className="" name="circle-o" /></div>;
            break;
          case 'PM10':
            name = 'PM10';
            unit = 'ug/m3';
            color = '#00C2FF';
            icon = <div id="circle" style={{ backgroundColor: color }}> <FontAwesome className="" name="dot-circle-o" /></div>;
            break;
          case 'PM25':
            name = 'PM2.5';
            unit = 'ug/m3';
            color = '#00C2FF';
            icon = <div id="circle" style={{ backgroundColor: color }}> <FontAwesome className="" name="bullseye" /></div>;
            break;
          case 'F_P':
            name = 'DeltaPresure';
            unit = 'pa';
            color = '#00C2FF';
            icon = <div id="circle" style={{ backgroundColor: color }}> <FontAwesome className="" name="tachometer" /></div>;
            break;
          case 'B_H':
            name = 'Humidity';
            unit = 'RH%';
            if ((this.state.readings.B_H >= 0 && this.state.readings.B_H < 30) || (this.state.readings.B_H >= 70 && this.state.readings.B_H < 100))
              color = 'red'
            else if ((this.state.readings.B_H >= 30 && this.state.readings.B_H < 40) || (this.state.readings.B_H >= 60 && this.state.readings.B_H < 70))
              color = '#FFD600'
            else
              color = '#00C2FF'
            icon = <div id="circle" style={{ backgroundColor: color }}> <FontAwesome className="" name="tint" /></div>;
            break;
          case 'c_i':
            name = 'iaq';
            unit = '';
            if (this.state.readings.c_i >= 0 && this.state.readings.c_i < 150)
              color = '#00C2FF'
            else if (this.state.readings.c_i >= 150 && this.state.readings.c_i < 251)
              color = '#FFD600'
            else
              color = 'red'
            icon = <div id="circle" style={{ backgroundColor: color }}><FontAwesome className="" name="leaf" /></div>;
            break;
          case 'c_c':
            name = 'CO2';
            unit = 'ppm';
            if (this.state.readings.c_c >= 400 && this.state.readings.c_c < 2500)
              color = '#00C2FF'
            else if (this.state.readings.c_c >= 2500 && this.state.readings.c_c < 4000)
              color = '#FFD600'
            else
              color = 'red'
            icon = <div id="circle" style={{ backgroundColor: color }}> <FontAwesome className="" name="cloud" /></div>;
            break;
          case 'c_v':
            name = 'VOC';
            unit = 'ppm';
            if (this.state.readings.c_v >= 0 && this.state.readings.c_v < 300)
              color = '#00C2FF'
            else if (this.state.readings.c_v >= 300 && this.state.readings.c_v < 600)
              color = '#FFD600'
            else
              color = 'red'
            icon = <div id="circle" style={{ backgroundColor: color }}> <FontAwesome className="" name="flask" /></div>;
            break;
          case 'wqi':
            name = 'WQI';
            unit = '';
            if (this.state.readings.wqi >= 61 && this.state.readings.wqi < 100)
              color = '#00C2FF'
            else if (this.state.readings.wqi >= 30 && this.state.readings.wqi < 60)
              color = '#FFD600'
            else
              color = 'red'
            icon = <div id="circle" style={{ backgroundColor: color }}> <FontAwesome className="" name="heart" /></div>;
            break;
          case 'i_pos':
            name = 'Ion+';
            unit = 'ion/ccm';
            if (this.state.readings.i_pos >= 101000 && this.state.readings.i_pos < 199900)
              color = '#00C2FF'
            else if (this.state.readings.wqi >= 2000 && this.state.readings.i_pos < 100000)
              color = '#FFD600'
            else
              color = 'red'
            icon = <div id="circle" style={{ backgroundColor: color }}> <FontAwesome className="" name="plus" /></div>;
            break;
          case 'i_neg':
            name = 'Ion-';
            unit = 'ion/ccm';
            if (this.state.readings.i_pos >= 101000 && this.state.readings.i_neg < 199900)
              color = '#00C2FF'
            else if (this.state.readings.wqi >= 2000 && this.state.readings.i_neg < 100000)
              color = '#FFD600'
            else
              color = 'red'
            icon = <div id="circle" style={{ backgroundColor: color }}> <FontAwesome className="" name="minus" /></div>;
            break;
           default:
            name = 'no';
        }
        if (name !== 'no' && name !== 'WQI') {
          button.push(< Button data-test="Button1" key={i} id={name} variant={this.state.active.includes(name.toLowerCase()) ? 'outline-primary' : 'light'}
            style={{ width: '270px', height: '60px', display: 'inline-flex', justifyContent: 'space-between' }} className="shadow rounded my-1 my-sm-2 mx-md-5"
            onClick={e => {
              let temp = this.state.active
              if (temp.includes(e.currentTarget.id.toLowerCase())) {
                temp = temp.filter(l => l != e.currentTarget.id.toLowerCase());
                this.setState({ active: temp });
              } else {
                temp.push(e.currentTarget.id.toLowerCase());
                this.setState({ active: temp });
              }
            }}>
            <div>
              {icon}
              <h6 style={{ fontWeight: 'bold', fontSize: 'smaller' }}>{name}</h6>
            </div>
            <div>
              <h6 className="mt-2" style={{ display: 'flex' }}>{name === 'Acceleration' ? Math.sqrt(Math.pow(this.state.readings[x][0], 2) + Math.pow(this.state.readings[x][1], 2) + Math.pow(this.state.readings[x][2], 2)).toFixed(4) : this.state.readings[x]}&nbsp;{unit}</h6>
              <p style={{ marginTop: '-10px', fontSize: '11px', color: 'gray' }}>{this.state.lastReading}</p>
            </div>
          </Button >)
        }
        i++;
      }
    }
    let deviceType = '';
    if (this.props.location.state !== undefined) {
      switch (this.props.location.state.type) {
        //waeev control
        case 'waeev sensor':
          deviceType = <img alt="" src={require('../waeev_control.png')} style={{ width: '100px' }} />;
          break;
        case 'waeev smart':
          deviceType = <img alt="" src={require('../waeev_smart.png')} style={{ width: '100px' }} />;
          break;
        // //waeev wall
        case 'wall sensor':
          deviceType = <img alt="" src={require('../waeev_wall.png')} style={{ width: '100px' }} />;
          break;
        case 'waeev tabletop':
          deviceType = <img alt="" src={require('../waeev_tabletop.png')} style={{ width: '100px' }} />;
          break;
        case 'waeev fan':
          deviceType = <img alt="" src={require('../waeev_fan.png')} style={{ width: '100px' }} />;
          break;

      }
    }

    return (
      <div style={{ overflowX: 'hidden', height: '100vh' }}>
        <Fragment>
          <NavBar />
          <div> <FontAwesome style={{ position: 'absolute', right: '10px', top: '80px', cursor: 'pointer' }} name="info-circle" onClick={() => this.setState({ deviceDetails: true })} /></div>
          <h1 className="mt-3" style={{ textAlign: 'center', color: 'white' }}>{this.props.location.state !== undefined ? this.props.location.state.label : null}</h1>

          <div className="mt-md-4" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

            {this.state.loading === true ?
              <p
                style={{ textAlign: 'center' }}
                className="mt-md-5"
                hidden={this.state.loading === true ? false : true}>
                loading please wait...
            </p> :
              <Fragment>
                <div className="row mx-5 px-5" style={{ overflow: 'hidden' }}>
                  <div className="mt-3 mx-5 px-5 row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {button}
                  </div>
                </div>
              </Fragment>}
            {this.state.control !== null && this.state.control === true ?
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="mt-2">
                <Card data-test="fan" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '280px', borderRadius: '20px' }}>
                  <h5>Fan</h5>
                  <Button onClick={() => this.setState({ fan: 'off' }, () => this.ControlFan())} id="fan" style={{ width: '50px', height: '50px', fontSize: '12px' }} variant={this.state.fan === 'off' ? 'primary' : "light"}>oFF</Button>
                  <Button onClick={() => this.setState({ fan: 'low' }, () => this.ControlFan())} id="fan" style={{ width: '60px', height: '60px' }} variant={this.state.fan === 'low' ? 'primary' : "light"}>Low</Button>
                  <Button onClick={() => this.setState({ fan: 'mid' }, () => this.ControlFan())} id="fan" style={{ width: '70px', height: '70px' }} variant={this.state.fan === 'mid' ? 'primary' : "light"}>Mid</Button>
                  <Button onClick={() => this.setState({ fan: 'high' }, () => this.ControlFan())} id="fan" style={{ width: '80px', height: '80px' }} variant={this.state.fan === 'high' ? 'primary' : "light"}>High</Button></Card>
              </div> : null}
          </div>

          <Button data-test="Button2" id="fan" style={{ width: '80px', height: '80px', zIndex: '99', position: 'fixed', bottom: '0', right: '0' }}
            disabled={this.state.active.length === 0 ? true : false}
            variant="light" onClick={() =>
              this.props.history.push('/Charts', { sensorsName: this.state.active, pk: this.props.location.state.pk, device: this.props.location.state.label })}>
            <FontAwesome name="area-chart" size="2x" /></Button>
          <Modal show={this.state.deviceDetails} onHide={() => this.setState({ deviceDetails: false })}>
            <div style={{ height: '10rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', flexWrap: 'wrap' }}>
              {deviceType}
              <div>
                <h6>Firmware Version: &nbsp;{this.props.location.state !== undefined ? this.props.location.state.fv : null}</h6>
                <h6>MQTT Connection: &nbsp;{this.state.mqtt}</h6>
                <h6>Mac Address: &nbsp;{this.props.location.state !== undefined ? this.props.location.state.mac.match(/.{1,2}/g).join(':') : null}</h6>
              </div>
            </div>
          </Modal>
        </Fragment>
      </div>

    );
  }
}

