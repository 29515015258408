
import React, { Component, Fragment } from 'react';
import NavBar from '../component/NavBar.js';
import { Navbar } from 'react-bootstrap';
import axios from '../axios.js';
export default class About extends Component {
  componentDidMount(){
    let data = localStorage.getItem('token');
      if(!data){
        this.props.history.replace("/Login");
      }else{
        axios.defaults.headers.common.Authorization =
        'Token ' + data;
      }
  }
  render() {
    return (
      <Fragment>
        <NavBar />
        <div style={{height: '100vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <h4 style={{textAlign: 'center'}} className="col-6 text-white">waeev air sanitizer application is associate program to make the usage of our waeev product more easier. 
          <br />
          <Navbar.Brand href="http://www.waeev.com" >www.waeev.com</Navbar.Brand>
          </h4>
        </div>

      </Fragment>
    );

  }
}

