
import React, { Component, Fragment } from 'react';
import NavBar from '../component/NavBar.js';
import { Form, Button, Col, Card, Modal } from 'react-bootstrap';
import axios from '../axios.js';
var FontAwesome = require('react-fontawesome')
var style = {
  h6: {
    lineHeight: '35px',
    cursor: 'pointer',
  }
}
export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DeviceId: '',
      deviceName: '',
      newLabel: '',
      oldID: '',
      newID: '',
      confirmID: '',
      error_ID: false,
      error_edit: false,
      error_message: 'yes',
      edit_ID: false,
      edit_name: false,
      delete: false,
      DeviceList: [],
    };
    this.registerID = this.registerID.bind(this);
    this.UnregisterID = this.UnregisterID.bind(this);
  }
  get_deviceID() {
    //set DeviceList
    axios.get('/rest-auth/user/')
      .then(async response => {
        let result = JSON.parse(response.request.response);
        this.setState({ DeviceList: result.devices, DeviceId: '', deviceName: '' })
      })
      .catch(
        function (error) {
          console.log(error);
        }
      );
  }
  componentDidMount() {
    let data = localStorage.getItem('token');
    if (data) {
      axios.defaults.headers.common.Authorization =
        'Token ' + data;
      this.get_deviceID();
    } else {
      this.props.history.replace("/Login");
    }

  }
  registerID(event) {
    event.preventDefault();
    axios.post('/devices/register/', {
      registration_id: this.state.DeviceId,
      label: this.state.deviceName,
    })
      .then(async response => {
        JSON.parse(response.request.response);
        this.setState({})
        this.get_deviceID()
      })
      .catch(
        function (error) {
          //wrong entered id
          if (error.response.request.response) {
            if (
              JSON.parse(error.response.request.response).hasOwnProperty(
                'detail',
              )
            ) {
              if (JSON.parse(
                error.response.request.response).detail === 'Device Not Found') {
                this.setState(
                  {
                    error_ID: true,
                    error_message: 'Device Not Found',
                  }
                );
              }
              if (JSON.parse(
                error.response.request.response).detail === 'Device Already Registered') {
                this.setState(
                  {
                    error_ID: true,
                    error_message: 'Device Already Registered',
                  }
                );
              }

            }
          } else {
            //if request faild
            this.setState({
              error_ID: true,
              error_message: 'Error network.',
            });
          }
        }.bind(this)
      );
  }
  UnregisterID() {
    // e.preventDefault();
    this.setState({ delete: false })
    axios.post('/devices/' + this.state.DeviceId + '/unregister/')
      .then(async response => {
        JSON.parse(response.request.response);
        this.setState({ delete: false })
        this.get_deviceID()
      })
      .catch(
        function (error) {
          console.log(error);
        }
      );
  }
  editName() {
    axios.post('/devices/' + this.state.DeviceId + '/edit_label/', { label: this.state.newLabel })
      .then(async response => {
        JSON.parse(response.request.response);
        this.setState({ edit_name: false })
        this.get_deviceID()
      })
      .catch(
        function (error) {
          console.log(error);
        }
      );
  }
  editID() {

    if(this.state.newID === this.state.confirmID){
      axios.post('/devices/' + this.state.DeviceId + '/edit_reg_id/', { old_id: this.state.oldID, new_id: this.state.newID })
      .then(async response => {
        JSON.parse(response.request.response);
        this.setState({ edit_ID: false })
        this.get_deviceID()
      })
      .catch(
        function (error) {
          console.log(error.response.request.response);
          if (
            JSON.parse(error.response.request.response).hasOwnProperty(
              'detail',
            )
          ) {
            if (JSON.parse(
              error.response.request.response,
            ).detail.includes('Wrong Registration ID')) {
              this.setState(
                {
                  error_edit: true,
                  error_message: 'Wrong Registration ID.',
                }
              );
            }
            if (JSON.parse(
              error.response.request.response,
            ).detail.includes('Invalid Registration ID')) {
              this.setState(
                {
                  error_edit: true,
                  error_message: 'Invalid Registration ID.',
                }
              );
            }
          }
        }.bind(this)
      );
    }
  }

  render() {
    let items = [];
    if (this.state.DeviceList.length === 0) {
      items.push(<div key={'1'} style={{ textAlign: 'center' }}><h4>No device yet</h4><hr /></div>)
    } else {
      this.state.DeviceList.map((el) => {
        items.push(<Card key={el.pk} style={{
          backgroundColor: 'rgba(255, 252, 252, 0.5)', borderRadius: '10px',
          display: 'flex', justifyContent: 'space-between', flexDirection: 'row'
        }}
        //(to do)add mac address
          className="p-3">
            <div style={{flexDirection:'column',display:'flex'}}>
            <h5 className="m-0">{el.label}<FontAwesome data-test="editLabel" id={el.pk} style={{ fontSize: '12px', cursor: 'pointer', float: 'right' }} name="pencil" onClick={(e) => { this.setState({ DeviceId: e.currentTarget.id, edit_name: true }) }} /></h5>
          <p className="m-0" style={{ fontSize: '12px', color: 'gray' }}>{el.mac_address.match(/.{1,2}/g).join(':')}</p>
            </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
            <FontAwesome data-test="editID" style={style.h6} id={el.pk} name="pencil-square-o" onClick={(e) => this.setState({ edit_ID: true, DeviceId: e.currentTarget.id })} />
           &nbsp;&nbsp;<FontAwesome data-test="delete" style={style.h6} id={el.pk} name="minus" onClick={(e) => this.setState({ delete: true, DeviceId: e.currentTarget.id })} />
          </div>
        </Card>)
      })
    }
    return (
      <Fragment>

        <div style={{ height: '100vh' }}>
          <Fragment>
            <NavBar />
            <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center' }}>
              <Form className="my-md-5 col-md-4">
                <Form.Label className="text-white">Register a device:</Form.Label>
                <Form.Row>
                  <Col md="4"  className="my-2">
                    <Form.Control data-test="name" className={this.state.error_ID === true && this.state.DeviceId !== '' ? "is-invalid shadow rounded" : 'shadow rounded'}
                      placeholder="Name" value={this.state.deviceName}
                      onChange={e => { this.setState({ deviceName: e.target.value, error_ID: false }) }} />
                  </Col>
                  <Col md="7" className="my-2">
                    <Form.Control data-test="ID" className={this.state.error_ID === true && this.state.DeviceId !== '' ? "is-invalid shadow rounded" : 'shadow rounded'}
                      placeholder="Registration ID" maxLength="20" value={this.state.DeviceId}
                      onChange={e => { this.setState({ DeviceId: e.target.value, error_ID: false }) }} />
                  </Col>
                  <Col  className="my-2">
                    <Button variant="primary" type="submit" style={{ backgroundColor: '#161525' }} onClick={this.registerID}
                      disabled={this.state.DeviceId !== '' && this.state.deviceName !== '' ? false : true} data-test="Button">OK</Button>
                  </Col>
                </Form.Row>
                <p
                  data-test="Error"
                  style={{ color: 'red' }}
                  hidden={this.state.error_ID === true && this.state.DeviceId !== '' ? false : true}>
                  {this.state.error_message}
                </p>
              </Form>

              <div className="col-md-4 mt-5">
                <h6 className="text-white">Devices:</h6>
                <div className="" style={{ backgroundColor: 'rgba(255, 252, 252, 0.5)', borderRadius: '10px', overflowY: 'scroll', height: 66 * 4 }}>
                  {items}
                </div>
              </div>
            </div>
          </Fragment>
        </div>


        <Modal show={this.state.edit_ID || this.state.edit_name || this.state.delete}
          onHide={() => { this.state.edit_ID === true ? this.setState({ edit_ID: false,DeviceId:'',error_message:"" }) : this.state.delete === true ? this.setState({ delete: false,DeviceId:'',error_message:"" }) : this.setState({ edit_name: false,DeviceId:'',error_message:"" }) }}>
          {this.state.edit_ID === true ?
            <Fragment>
              <Modal.Header closeButton>
                <Modal.Title>Edit registration ID</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group><Form.Control maxLength="20" className='shadow rounded' placeholder="Old Registration ID" 
                  onChange={e => this.setState({ error_edit: false, oldID: e.target.value })} /></Form.Group>
                  <Form.Group><Form.Control maxLength="20" className='shadow rounded' placeholder="New Registration ID" 
                   onChange={e =>e.target.value === this.state.confirmID ?this.setState({ newID: e.target.value,error_edit: false,error_message:'' },() =>console.log(this.state.newID)):this.setState({ newID: e.target.value,error_edit: true,error_message:"registration id doesn't match" },() =>console.log(this.state.newID))} /></Form.Group>
                  <Form.Group><Form.Control maxLength="20" className='shadow rounded' placeholder="Confirm Registration ID" 
                  onChange={e =>e.target.value === this.state.newID?this.setState({ confirmID: e.target.value,error_edit: false,error_message:'' },() =>console.log(this.state.confirmID)):this.setState({confirmID: e.target.value,error_edit: true,error_message:"registration id doesn't match" },() =>console.log(this.state.confirmID))} /></Form.Group>
                  <p
                    data-test="Error"
                    style={{ color: 'red' }}
                    hidden={this.state.error_edit === true && this.state.DeviceId !== '' ? false : true}>
                    {this.state.error_message}
                  </p>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={() => this.state.error_edit === false?this.editID():null}>
                  Save
          </Button>
              </Modal.Footer>
            </Fragment>
            : this.state.delete === true ?
              <Fragment>
                <Modal.Header closeButton>
                  <Modal.Title>Unregister Device</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete the device?
              </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={this.UnregisterID}>
                    Yes
                      </Button>
                  <Button variant="primary" onClick={() => { this.setState({ delete: false }) }}>
                    NO
                      </Button>
                </Modal.Footer>
              </Fragment>
              :
              <Fragment>
                <Modal.Header closeButton>
                  <Modal.Title>Edit name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Control maxLength="20" className='shadow rounded' onChange={e => this.setState({ newLabel: e.target.value })}
                    placeholder="Enter new device label" onKeyPress={(e) =>e.key === 'Enter'? this.editName() : null} />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={() => this.editName()}>
                    Save
                      </Button>
                </Modal.Footer>
              </Fragment>

          }
        </Modal>
      </Fragment>
    );
  }
}

