import React, { Fragment, Component } from 'react';
import NavBar from '../component/NavBar.js';
import Chart from 'react-apexcharts'
import { Form, Button} from 'react-bootstrap';
import axios from '../axios.js';
import '../App.css';
var FontAwesome = require('react-fontawesome')
export default class Charts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sensorsIcon: [{ name: 'temperature', icon: 'thermometer-half' },{ name: 'voc', icon: 'flask' }, { name: 'pressure', icon: "tachometer" }, { name: 'gas', icon: "skyatlas" },
      { name: 'humidity', icon: "tint" }, { name: 'iaq', icon: "leaf" }, { name: 'co2', icon: "cloud" }, { name: 'presence', icon: "child" },
      { name: 'sound', icon: "volume-down" }, { name: 'light', icon: "lightbulb-o" }, { name: 'dust1', icon: "circle-o" }, { name: 'dust10', icon: "dot-circle-o" }
        , { name: 'dust25', icon: "bullseye" }, { name: 'acceleration', icon: "bolt" },{ name: 'delta pressure', icon: "tachometer" }],
      selectedTocompare: [],
      interval: "1h",
      DeviceList: [],
      selectedSensors: [],
      selectdevice: 0,
      NameDevice: '',
      deviceTab: false,
      options: {
        chart: {
          id: 'apexchart',
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          //categories: [],
          type: 'datetime',
          labels: { datetimeUTC: false }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              if(value !== undefined)
                return value.toFixed(2);
              else
                return 0;
            }
          }
        },
        tooltip: {
          x: {
            show: true,
            format: "dd/MM HH:mm"
          },
          
        },
        gradient: {
          opacityFrom: 0.7,
          opacityTo: 0.2,
          stops: [0, 100]
        },
      },
      series: [],
    }
    this.updateChart = this.updateChart.bind(this);
  }
  getDevices() {
    axios.get('/rest-auth/user/')
      .then(async response => {
        let result = JSON.parse(response.request.response);
        this.setState({ DeviceList: result.devices })
      })
      .catch(
        function (error) {
          console.log(error);
        }
      );
  }
  componentDidMount() {
    let data = localStorage.getItem('token');
    if (data) {
      axios.defaults.headers.common.Authorization =
        'Token ' + data;
      if (this.props.location.state === undefined) {
        this.props.history.replace('/Cockpit');
      } else {
        this.setState({selectdevice: this.props.location.state.pk,selectedTocompare: [...this.state.selectedTocompare, { pk: this.props.location.state.pk, name: this.props.location.state.device, sensors: this.props.location.state.sensorsName }] }, () => this.setState({selectedSensors: this.props.location.state.sensorsName},()=>this.plotChart()))
      }
      //this.plotChart();
    } else {
      this.props.history.replace('/Cockpit');
    }
 
  }
  getInterval(e) {
    this.setState({ interval: e.target.value }, () => console.log(this.state.interval))
    // plot chart
    this.setState({
      series: [],
    }, () => {
      this.state.selectedTocompare.map(el => {
        if (!isNaN(el.pk)) {
          el.sensors.map(sens => {
            axios.get('/devices/' + el.pk + '/readings/?reading=' + sens + '&interval=' + this.state.interval)

              .then(async response => {
                let result = JSON.parse(response.request.response);
                this.updateChart(sens, el.name, result);
              })
              .catch(
                function (error) {
                  console.log(error);
                }
              );
          })
        }
      })
    })
  }
  plotChart() {
    //reading name
    //this.setState({ loadChart: true })
    //send request with reading name and interval to plot chart
    this.setState({
      series: [],
    }, () => {
      this.state.selectedTocompare.map(el => {
        if (!isNaN(el.pk)) {
          el.sensors.map(sens => {
            axios.get('/devices/' + el.pk + '/readings/?reading=' + sens + '&interval=' + this.state.interval)

              .then(async response => {
                let result = JSON.parse(response.request.response);
                this.updateChart(sens, el.name, result);
              })
              .catch(
                function (error) {
                  console.log(error);
                }
              );
          })
        }
      })
    })
  }
  updateChart(name, dev, value) {
    this.setState({
      //loadChart: false,
      series: [
        ...this.state.series,
        {
          name: name.toString()+'('+dev+')',
          data: value
        }
      ],

    })

  }
  render() {
    let items = [];
    let sensors = [];
    if (this.state.DeviceList.length === 0) {
      items.push(<option key={'1'} disabled>select a device</option>)
    } else {
      //items.push(<option key={'0'} value={0} >select a device</option>)
      items.push(<option key={this.state.selectedTocompare[0].pk} value={this.state.selectedTocompare[0].pk} >{this.state.selectedTocompare[0].name}</option>)
      this.state.DeviceList.map((el) => {
        if(this.props.location.state !== undefined){
          //(to do)display selected device as default
          if (el.pk !== this.props.location.state.pk) {
            items.push(<option key={el.pk} value={el.pk}>{el.label}</option>)
          }
        }
      })
    }
    //put sensors for each device.
    var temp = this.state.selectedSensors;
    //if (this.state.selectdevice !== 0) {
      this.state.sensorsIcon.map(el => {
        sensors.push(<div key={el.name} data-key={el.name} className="m-1" id="circle" style={{
          cursor: 'pointer',
          backgroundColor: this.state.selectedSensors.includes(el.name) ? '#007bff' : '#b3e2fe',
          display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}
        >
          <FontAwesome id={el.name} name={el.icon}
            onClick={e => {
              if (temp.includes(e.currentTarget.id)) {
                temp = temp.filter(l => l != e.currentTarget.id);
              } else {
                temp = [...temp, e.currentTarget.id];
              }
              this.setState({ selectedSensors: temp });

            }} /></div>)
      })
   // }
    return (
      <div style={{ overflowX: 'hidden', height: '100vh' }}>
        <Fragment>
          <NavBar />
          <div className="" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', alignItems: 'center' }}>

            <div className="my-2 shadow rounded" style={{ width: '95vw' }}>
              <Chart options={this.state.options} series={this.state.series} type="area" height='500vh' data-test="chart" />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '60vw' }}>
              <Form.Control as="select" className="col-md-3 shadow rounded" style={{ alignSelf: 'center' }}
                onChange={this.getInterval.bind(this)}
                data-test="select"
              >
                <option value="1h">Last 1 hour</option>
                <option value="24h">Last 24 hours</option>
                <option value="1M">Last month</option>
                <option value="1Y" disabled>Last year</option>
              </Form.Control>
            </div>
          </div>
          {this.state.deviceTab === true ?
            <div id="tab" data-test="tab">
              <FontAwesome name="sort-down" size="2x" style={{ cursor: 'pointer' }} onClick={() => this.setState({ deviceTab: false })} />
              <Form className="mt-3" style={{ width: '13rem' }}>
                <Form.Control as="select" className="shadow rounded" defaultValue={this.state.selectdevice}
                  onChange={(e) => {
                     this.setState({ selectdevice: Number(e.target.value) ,NameDevice:e.target.options[e.target.selectedIndex].text}, () => {
                      this.setState({ selectedSensors: this.state.selectedTocompare.find(s => s.pk == this.state.selectdevice) ? this.state.selectedTocompare.find(s => s.pk == this.state.selectdevice).sensors : [] })
                    })
                  }}>
                  {items}
                </Form.Control>
              </Form>
              <div style={{ display: 'flex', flexWrap: 'wrap' }} className="mt-2 py-2 px-5">

                {sensors}
              </div>
              <Button data-test="Button1" style={{ width: '90px', height: '50px', color: ' #17a2b8' }} variant="light"
                // disabled={this.state.selectdevice === 0 ? true : false}
                onClick={() => {
                  let found = false;
                  this.state.selectedTocompare.map(el => {
                    if (el.pk === this.state.selectdevice) {
                      found = true;
                      el.sensors = this.state.selectedSensors;
                      this.plotChart();
                    }
                  })
                  if (found !== true) {
                    this.setState({
                      selectedTocompare: [...this.state.selectedTocompare, { pk: this.state.selectdevice,name: this.state.NameDevice, sensors: this.state.selectedSensors }]
                    },
                      () => { this.plotChart(); })
                  }
                }}>Update</Button>

            </div>
            :
            <Button data-test="Button2" id="fan" style={{ width: '80px', height: '80px', zIndex: '99', position: 'fixed', bottom: '0', right: '0' }}
              variant="light" onClick={() => { this.setState({ deviceTab: true }); this.getDevices(); }}>
              <FontAwesome name="plus" size="2x" /></Button>}
        </Fragment>
      </div >

    );
  }

}

